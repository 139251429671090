:root {
  --bg-color-offwhite: #F3F2EA;
  --color-impact: #D8FF5C;
  --gutter-big: 62px 10%;
  --gutter-left-right: 0 10%;
  --gutter-small: 30px 20px;
}
.centered-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
}

.arrow-down-wrapper {
  padding: 15px;
  width: fit-content;
  background-color: var(--color-impact);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow-down {
  height: 30px;
  width: 30px;
}

.fixed-max-width {
  max-width: 1280px;
}

 a:link {
  color: black;
  opacity: 1;
}

a:visited {
  color: black;
  opacity: 1;
}

a:hover {
  color: grey;
}

a:active {
  color: grey;
} 

h2 {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'neue-haas-unica-pro-regular';
  src: url('/public/fonts/NeueHaasUnicaPro-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'neue-haas-unica-pro-medium';
  src: url('/public/fonts/NeueHaasUnicaPro-Medium.ttf') format('truetype');
}

body {
  font-family: 'neue-haas-unica-pro-regular', sans-serif;
}

.header {
  background-color: var(--bg-color-offwhite);
}

.header-title {
  margin: 50px 0;
  font-family: 'neue-haas-unica-pro-medium', sans-serif;
  letter-spacing: -3px;
  font-weight: 500;
  line-height: 90px;
  font-size: 90px;
  max-width: 1000px;
}

.header-intro {
  font-weight: 400;
  line-height: 30px;
  font-size: 20px;
  max-width: 850px;
  opacity: .8;
}

.impact {
  font-weight: bold;
  position: relative;
  box-shadow: inset 0 -10px 0 0 var(--color-impact);
}

.impact-sm {
  font-weight: normal;
}

.contact-background {
  background-image: linear-gradient(var(--bg-color-offwhite), var(--bg-color-offwhite));
  background-size: 100% 50%;
  background-repeat: no-repeat;
  background-position: top;
}
.contact-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.contact-row > :first-child {
  flex-basis: 60%;
}

.contact-row > :nth-child(2) {
  flex-basis: 40%;
}
.logo{ 
  width: 200px;
}

.gutter {
  padding: var(--gutter-big);
}

.gutter-left-right {
  padding: var(--gutter-left-right);
}

.bubble {
  background-color: var(--color-impact);
  padding: 46px 106px;
  border-radius: 10px;
  max-width: calc(1280px - 160px);
}

.contact-title {
  font-family: 'neue-haas-unica-pro-medium', sans-serif;
  font-weight: 500;
  font-size: 60px;
  line-height: 68.4px;
  letter-spacing: -3px;
}

.contact-item-wrapper {
  display: flex;
  flex-direction: row;
  gap: 20%;
}

.contact-item-title {
  font-family: 'neue-haas-unica-pro-medium', sans-serif;
  font-weight: 500;
  font-size: 40px;
  letter-spacing: -1px;
  margin: 40px 0 0px 0;
}

.contact-item-text {
  font-weight: 500;
  line-height: 33.12px;
  font-size: 24px;
  padding-bottom: 10px;
}

.case-title {
  margin: 1em 0;
  padding: 0;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 18px;
}

.case-title-big {
  padding: 0;
  margin: 0;
  font-family: 'neue-haas-unica-pro-medium', sans-serif;
  font-weight: 500;
  font-size: 70px;
  line-height: 72px;
  letter-spacing: -2px;
}

.case-img-tall {
  max-width: 581px;
  max-height: 743px;
}
.case-img-small {
  display: block;
  max-width: 516px;
  max-height: 345px;
}

.case-img-small-mobile {
  display: none;
}
.case-intro {
  margin: 2em 0 2.5em 0;
  font-size: 20px;
  opacity: .8;
}

.impact-title {
  position: relative;
}
.impact-title::after {
  content: "";
  position: absolute;
  bottom: 17px;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: var(--color-impact);
  z-index: -1;
}

.case-landmakers {
  display: flex;
  flex-direction: row;
  max-width: 1280px;
  gap: 5%;
}

.quote-title {
  font-family: 'neue-haas-unica-pro-medium', sans-serif;
  font-weight: 500;
  font-size: 60px;
  line-height: 68.4px;
  letter-spacing: -2px;
}

.quote-text {
  font-size: 24px;
  line-height: 34px;
}

.case-img {
  margin: 24px 0 0 10px;
  max-width: 548px;
  max-height: 460px;
  object-fit: cover;
  border-radius: 10px;
}

.case-item {
  display: flex;
  flex-direction: row;
  gap: 5%;
  max-width: 1280px;
}

@media (max-width: 767px) {

  .contact-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    
  }

  .contact-mobile-gutter {
    padding: 30px !important;
  }

  .case-item-ob {
    flex-direction: column-reverse;
  }
  .case-item-as {
    flex-direction: column;
  }
  .gutter {
    padding: var(--gutter-small)
  }

  .gutter-left-right {
    padding: 0 20px;
  }
  .logo {
    width: 123px;
    margin-top: 30px;
  }
  .header-title {
    font-size: 34px;
    max-width: fit-content;
    line-height: 38px;
    letter-spacing: -1px;
    margin-top: 40px;
    margin-bottom: 25px;
  }

  .header-intro {
    max-width: 100%;
    font-size: 15px;
    line-height: 23.25px;
  }

  .bubble {
    background-color: var(--color-impact);
    padding: 10px 30px;
    border-radius: 10px;
  }

  .contact-title {
    font-family: 'neue-haas-unica-pro-medium', sans-serif;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: -1px;
  }

  .contact-item-title {
    font-family: 'neue-haas-unica-pro-medium', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-top: 20px;
    letter-spacing: 0;
  }
  
  .contact-item-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 0;
    margin-bottom: 0;
    margin-top: 5px;
  }

  .case-img {
    margin: 0;
  }

  .case-img-tall {
    max-width: 150px;
    max-height: 245px;
  }

  .case-img-small {
    display: none;
  }

  .case-img-small-mobile {
    display: block;
    max-width: 130px;
    max-height: 107px;
    align-self: flex-end;
    padding-bottom: 10%;
  }

  .case-title {
    margin: 30px 0 10px;
    font-size: 18px;
  }
  
  .case-intro {
    font-size: 16px;
    margin-bottom: 0;
  }
  
  .case-title-big {
    font-size: 42px;
    line-height: 44px;
  }
  
  .impact-title::after {
    content: "";
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 100%;
    height: 10px;
    background-color: var(--color-impact);
    z-index: -1;
  }

  .case-landmakers {
    max-width: 336px;
    display: flex;
    flex-direction: column;
    gap: 5%;
  }

  .case-landmakers-images {
    display: flex;
    flex-direction: row;
    gap: 5%;
    margin: 10% 0;
  }

  .quote-title {
    padding: 30px 0 15px 0;
    font-size: 25px;
    line-height: 29px;
    letter-spacing: -1px;
  }
  
  .quote-text {
    font-size: 15px;
    line-height: 23.25px;
    padding-bottom: 25px;
  }
}